import request from '@/request-set';

export function getBooks() {
    return request({
        url: '/skbook',
        method: 'GET'
    })
        .then(res => res.data)
        .then(({ list }) => {
            return list.map(row => {
                row.isNew = new Date() - new Date(row.createTime) < 86400000 * 14;

                return row;
            })
        });
}