<template>
  <div class="block">
    <van-tabs ref="tab" v-model:active="active" shrink sticky>
      <van-tab title="专辑列表">
        <div class="overall">
          <Waterfall :list="list" background-color="transparent" :width="10" :breakpoints="{ 500: { rowPerView: 2 } }">
            <template #item="{ item }">
              <div class="card" @click="linkto(item)">
                <van-image :src="item.cover" fit="cover" position="left" width="100%"
                  style="border-radius:6px;overflow:hidden;">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <p class="title">{{ item.name }}</p>
                <div class="text">作者：{{ item.author || '-' }}</div>
                <div class="text">演播：{{ item.announcer || '-' }}</div>
                <div v-if="item.isNew" class="is-new">
                  <div class="is-new-container">最近更新</div>
                </div>
              </div>
            </template>
          </Waterfall>
        </div>
      </van-tab>
    </van-tabs>




    <!-- <h2 class="van-doc-demo-block__title">专辑列表</h2> -->

    <!-- <van-row gutter="20">
      <van-col span="8" style="text-align:center" v-for="item in list" :key="item.id">
        <div @click="linkto(item)">
          <van-image :src="item.cover" fit="cover" position="left" width="8rem" height="10.7rem">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div class="text">{{ item.name }}</div>
        </div>
      </van-col>
    </van-row> -->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  Row as VanRow,
  Col as VanCol,
  Image as VanImage,
  Loading as VanLoading,
  Tabs as VanTabs,
  Tab as VanTab
} from 'vant';
import request from '@/request-set';

import { LazyImg, Waterfall } from 'vue-waterfall-plugin-next'
import 'vue-waterfall-plugin-next/style.css'
import { getBooks } from '@/api';

const tab = ref(null);
const $router = useRouter();
const list = ref([]);
const active = ref('');

function linkto(item) {
  $router.push({
    name: 'tklist',
    query: {
      id: item.id
    }
  });
}

onMounted(() => {
  document.title = '专辑列表 - Tktine听书';

  setTimeout(() => {
    tab.value?.scrollTo();
  }, 100);

  getBooks()
    .then(books => {
      list.value = books;
    });
});

</script>

<style lang="scss" scoped>
.block {
  padding: 0 10px;
  background-color: #f0f0f0;
}

.title {
  margin: 8px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.text {
  font-size: 12px;
  text-align: left;
  color: #aaa;
}

.card {
  position: relative;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
}

.overall {
  margin-top: 10px;
}

:deep(.van-tabs__nav) {
  background: #f0f0f0;
}

.is-new {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  overflow: hidden;

  .is-new-container {
    position: absolute;
    top: 10px;
    right: 32px;
    background-color: #07c160;
    color: #fff;
    transform-origin: center center;
    transform: translate(50%, 50%) rotate(45deg);
    padding: 4px 0;
    width: 1000%;
    text-align: center;
    line-height: 16px;
    letter-spacing: 4px;
  }
}
</style>