import axios from 'axios';
import qs from 'qs';
import store from '@/store';
import config from './setting';
// import { $confirmMessageError } from './message';
import { ajaxErrorMessage } from '@/error';

// create an axios instance
const service = axios.create({
    // url = base url + namespace + request url
    baseURL: config.apiNameSpace,

    // send cookies when cross-domain requests
    // withCredentials: true,

    // request timeout
    timeout: 0
});

// request interceptor
service.interceptors.request.use(
    AxiosRequestConfig => {
        // do something before request is sent
        AxiosRequestConfig.data = qs.stringify(AxiosRequestConfig.data);

        AxiosRequestConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded';

        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            // AxiosRequestConfig.headers['token'] = getToken();
        }

        return AxiosRequestConfig;
    },
    error => {
    // do something with request error
    // for debug
        console.log(error);
        error.message = '服务器繁忙，请稍后再试';

        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(

    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    function(response) {
        const res = response.data;

        // if the custom code is not 200, it is judged as an error.
        // code = 200 时视为请求成功
        if (res.code !== 200) {
            // 8000: Illegal token; 8001: Other clients logged in; 8002: Token expired;
            // 验证身份，如果存在以下code号，视为重新登录操作
            // if (res.code === 8000 || res.code === 8001 || res.code === 8002) {
            //     // to re-login
            //     console.error('你错了');
            //     // $confirmMessageError('您的登录已失效，请重新登录', {
            //     //     confirmButtonText: '重新登录',
            //     //     showCancelButton : false
            //     // })
            //     //     .then(() => {
            //     //         store.dispatch('user/resetToken').then(() => {
            //     //             location.reload();
            //     //         });
            //     //     });
            // } else if (res.code >= 0) {
            //     // $errorMessage(res.message || '请求失败');
            //     // Message({
            //     //   message: res.message || '请求失败',
            //     //   type: 'error',
            //     //   duration: 5 * 1000
            //     // });
            // }

            let showMsg = '';

            if (res.code === -1) {
                showMsg = res.message + 'deleteFail';
            } else {
                showMsg = res.message;
            }

            const error = new Error(ajaxErrorMessage(showMsg));

            error.response = res;

            return Promise.reject(error);
        }

        return res;
    },
    error => {
        console.log('err' + error);
        error.message = ajaxErrorMessage(error.message) || '请求失败';

        return Promise.reject(error);
    }
);

export default service;
