import request from './request';

export default function(options = {}) {
    if (typeof options == 'string') {
        options = {
            url: options
        };
    }

    options = Object.assign({
        hint  : true,
        method: 'POST',
        params: {},
        data  : {}
    }, options);

    const commons = {};

    // if (options.url.indexOf('/') == 0) {
    //     options.url = process.env.VUE_APP_BASE_API + options.url;
    // }

    if (options.method == 'POST') {
        options.data = Object.assign(commons, options.data);
    } else {
        options.params = Object.assign(commons, options.params);
    }

    return request({
        url   : options.url || '',
        method: options.method,
        params: options.params,
        data  : options.data
    })
        .catch(error => {
            return Promise.reject(error);
        });
}
