import request from '@/request-set';

export function getBookVersion(id) {
    return request({
        url: '/skbook_version/' + id,
        method: 'GET'
    })
        .then(res => {
            const { version } = res.data;

            return version;
        })
        .catch(() => 0);
}

export function getBookDetail(id) {
    return request({
        url: '/skbook/' + id,
        method: 'GET'
    })
        .then(res => res.data)
}

async function getDetailExec(id) {
    const bookIdKey = `tktine/book/${id}`;
    let localBook;

    try {
        localBook = JSON.parse(localStorage.getItem(bookIdKey));
    } catch (error) {
        console.log('未找到相关有效数据，仍以服务器数据访问');
    }
    
    if (localBook) {
        const version = await getBookVersion(id);

        if (version == localBook.version) {
            return Promise.resolve(localBook);
        }
    }

    return getBookDetail(id)
        .then(data => {
            localStorage.setItem(bookIdKey, JSON.stringify(data));

            return data;
        });
}

export function getDetail(id) {
    return getDetailExec(id)
        .then(data => {
            data.isNew = new Date() - new Date(data.createTime) < 86400000 * 14;

            return data;
        });
}
