<template>
  <router-view class="router-view" v-slot="{ Component }">
    <transition :name="transitionName">
      <keep-alive exclude="TkList">
        <component :is="Component" :key="$route.path" />
      </keep-alive>
    </transition>
  </router-view>
</template>

<script>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: 'App',
  setup() {
    const transition = ['slide-left', 'slide-right']
    let transitionName = ref(transition[0])
    const route = useRoute()

    // 监控路由的变化
    watch(
      () => route.meta.index,
      (newIndex, oldIndex) => {
        if (newIndex > oldIndex) {
          transitionName.value = transition[0]
        } else {
          transitionName.value = transition[1]
        }
      }
    )

    return { transitionName }
  },
}
</script>

<style lang="scss">
.router-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>